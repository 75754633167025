import React, { useState, useEffect } from 'react';
import backgroundImage from "../assets/images/Timer.jpg";

const Timer = () => {
  const calculateTimeLeft = () => {
    const deadline = new Date('February 4, 2025 00:00:00');
    const now = new Date();
    const difference = deadline - now;

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const containerStyle = {
    position: 'relative',
    textAlign: 'center',
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '20%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px'
  };

  const overlayStyle = {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    padding: '20px',
    borderRadius: '15px',
    color: 'white',
    animation: 'fadeIn 1s ease-in-out',
    maxWidth: '90%',
    boxSizing: 'border-box'
  };

  const countdownStyle = {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
    flexWrap: 'wrap'
  };

  const timeBoxStyle = {
    margin: '10px 20px',
    textAlign: 'center',
    animation: 'pulse 1s infinite'
  };

  const timeStyle = {
    fontSize: '3em',
    fontWeight: 'bold'
  };

  const labelStyle = {
    fontSize: '1.5em'
  };

  const headingStyle = {
    fontSize: '2em',
    animation: 'fadeIn 2s ease-in-out'
  };

  const subheadingStyle = {
    fontSize: '1.5em',
    animation: 'fadeIn 2s ease-in-out',
    animationDelay: '1s'
  };

  const keyframesStyle = `
    @keyframes fadeIn {
      0% { opacity: 0; }
      100% { opacity: 1; }
    }

    @keyframes pulse {
      0% { transform: scale(1); }
      50% { transform: scale(1.05); }
      100% { transform: scale(1); }
    }

    @media (max-width: 600px) {
      ${timeStyle} {
        fontSize: 2em;
      }

      ${labelStyle} {
        fontSize: 1em;
      }

      ${headingStyle} {
        fontSize: 1.5em;
      }

      ${subheadingStyle} {
        fontSize: 1em;
      }

      ${timeBoxStyle} {
        margin: 5px 10px;
      }
    }
  `;

  return (
    <div style={containerStyle}>
      <style>
        {keyframesStyle}
      </style>
      <div style={overlayStyle}>
        <h1 style={headingStyle}>TIME IS RUNNING OUT TO FILE YOUR CLAIM !</h1>
        <h2 style={subheadingStyle}>The February 4th deadline is approaching fast. Don’t miss out!</h2>
        <div style={countdownStyle}>
          <div style={timeBoxStyle}>
            <div style={timeStyle}>{timeLeft.days}</div>
            <div style={labelStyle}>Days</div>
          </div>
          <div style={timeBoxStyle}>
            <div style={timeStyle}>{timeLeft.hours}</div>
            <div style={labelStyle}>Hours</div>
          </div>
          <div style={timeBoxStyle}>
            <div style={timeStyle}>{timeLeft.minutes}</div>
            <div style={labelStyle}>Minutes</div>
          </div>
          <div style={timeBoxStyle}>
            <div style={timeStyle}>{timeLeft.seconds}</div>
            <div style={labelStyle}>Seconds</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timer;
